<template>
  <div class="page_container">
    <el-carousel
      :height="$store.state.isPhone ? '350px' : '750px'"
      :interval="4000"
      :autoplay="true"
      arrow="never"
      @change="handleChange"
    >
      <el-carousel-item v-for="(item, i) in bannerList" :key="item.cn">
        <div
          :class="['banner', { banneracive: i === index }]"
          :style="`background:url(${item.img}) no-repeat;position:relative;}`"
        ></div>
        <div class="banner_content">
          <p class="banner-title">{{ item.cn }}</p>
          <p class="banner-desc">{{ item.en }}</p>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="p-page1" v-if="$store.state.isPhone">
      <!-- <p class="p-title">企业文化</p> -->
      <div>
        <div class="p-content">
          <el-row :gutter="15">
            <el-col
              v-for="(item1, i2) in list1"
              :key="item1.title + i2"
              :span="24"
            >
              <span>{{ item1.desc }}</span>
              <el-divider content-position="right">{{
                item1.title
              }}</el-divider>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="page1" v-if="!$store.state.isPhone">
      <div class="page-container">
        <div class="page_title">
          围绕制造业数字化转型升级提供全方位一体化服务
        </div>
        <el-row>
          <el-col
            :span="8"
            v-for="item in list1"
            :key="item.title"
            class="page_item_container"
          >
            <div class="page_item">
              <el-image fit="contain" :src="item.icon" class="icon"></el-image>
              <p class="title">{{ item.title }}</p>
              <p class="desc">{{ item.desc }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="page2 page-grey" v-if="!$store.state.isPhone">
      <h-title msg="从工业中来到工业中去，中智软创助力中国制造"></h-title>
      <div class="description clearfix page-container">
        <div class="desc-l">
          <div>
            <div>
              <el-carousel
                height="480px"
                direction="vertical"
                :autoplay="false"
                indicator-position="none"
                ref="carousel"
              >
                <el-carousel-item
                  v-for="item in list2"
                  :key="item.title"
                  :name="item.title"
                >
                  <div class="page2-item">
                    <div class="content">
                      <p class="title-l">{{ item.title }}</p>
                      <el-row>
                        <el-col :span="11">
                          <div class="content-l">
                            <div class="page2-img"></div>
                            <el-image
                              :src="item.img"
                              class="img"
                              fit="cover"
                            ></el-image>
                          </div>
                        </el-col>
                        <el-col :span="13">
                          <div class="line"></div>
                          <h2 class="title">{{ `什么是${item.title}?` }}</h2>
                          <p class="desc">{{ item.desc }}</p>
                          <el-row>
                            <el-col
                              v-for="(item1, i2) in item.list"
                              :key="i2"
                              :span="12"
                              class="item"
                            >
                              <span class="dot"></span>
                              <span class="item_title">{{ item1 }}</span>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="desc-r">
          <div>
            <ul>
              <li
                :class="`${page2Index == i && 'active'}`"
                :style="
                  `height: 152px;background-color:${
                    page2Index == i ? '#fff' : colors[i]
                  }`
                "
                v-for="(item, i) in list2"
                :key="item.title"
                @mouseenter="handleSetActive(i, item.title)"
              >
                <div class="left">
                  <span class="num">{{ "0 " + (i + 1) }}</span>
                </div>
                <div class="right">
                  <el-image
                    fit="contain"
                    :src="item.icon"
                    class="page2-icon"
                  ></el-image>
                  <span class="cn">{{ item.title }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="page3" v-if="!$store.state.isPhone">
      <div class="page-container">
        <div class="page-l">
          <div class="page3-title">行业系列产品解决方案</div>
          <div>
            <div
              v-for="(item, i) in list3"
              :key="item.title"
              :class="[
                'page3-l-item',
                { 'page3-l-item_active': page3Index == i },
              ]"
              @mouseenter="page3Index = i"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <div
          class="page-r"
          :style="`background-image:url(${list3[page3Index].img})`"
        >
          <div class="bg"></div>
          <div class="title">
            {{ list3[page3Index].title }}
          </div>
          <div class="actitle" @click="handleTo('/industry')">
            <div class="ac_title ">
              {{ list3[page3Index].title }}
              <i class="el-icon-right"></i>
            </div>
            <!-- <p class="desc">
              {{list3[page3Index].desc}}
            </p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="page2 page-grey" v-if="$store.state.isPhone">
      <h-title msg="从工业中来到工业中去，中智软创助力中国制造"></h-title>
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item
          v-for="(item, i) in list2"
          :key="item.title"
          :title="item.title"
          :name="i + 1"
        >
          <div class="page2-item">
            <div class="content">
              <p class="desc">{{ item.desc }}</p>
              <el-row>
                <el-col
                  v-for="(item1, i2) in item.list"
                  :key="i2"
                  :span="24"
                  class="item"
                >
                  <span class="dot"></span>
                  <span class="item_title">{{ item1 }}</span>
                </el-col>
              </el-row>
              <el-image
                fit="contain"
                :src="item.img"
                style="width:100%"
              ></el-image>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div class="p-page3" v-if="$store.state.isPhone">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="item in list3" :key="item.title">
          <div class="page-r" :style="`background-image:url(${item.img})`">
            <div class="bg"></div>
            <div class="actitle" @click="handleTo('/industry')">
              <div class="ac_title">
                {{ item.title }}
              </div>
              <!-- <p class="desc">
                {{item.desc}}
              </p> -->
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <div class="page4 page-grey">
      <h-title
        msg="引入优质伙伴共同建设化工行业平台生态网络，为客户提供全方位价值服务"
      ></h-title>
      <div class="page-container">
        <div class="page4-tab-contain">
          <span
            :class="`page4-tab ${page4Tab == 0 && 'page4-ac-tab'}`"
            @click="page4Tab = 0"
            >合作伙伴</span
          >
          <span
            :class="`page4-tab ${page4Tab == 1 && 'page4-ac-tab'}`"
            @click="page4Tab = 1"
            >战略客户</span
          >
        </div>
        <el-row>
          <div
            v-for="(item, i) in list4[page4Tab].list"
            :key="i"
            class="clearfix"
          >
            <div style="margin-left:25px;color:#999">{{ item.title }}</div>
            <el-col
              v-for="(item1, i) in item.data"
              :key="i"
              :xs="12"
              :sm="12"
              :md="6"
              :lg="4"
              :xl="4"
            >
              <div class="page1-item animate__animated ">
                <el-image fit="contain" :src="item1.img"></el-image>
              </div>
            </el-col>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      activeName: 1,
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      index: 0,
      page3Index: 0,
      page4Tab: 0,
      bannerList: [
        {
          img: require("../../assets/home/n-h-banner1.png"),
          cn: "应用工业互联网、人工智能、大数据等技术",
          en:
            "Apply industrial Internet, artificial intelligence, big data and other technologies",
        },
        {
          img: require("../../assets/home/n-h-banner2.png"),
          cn: "聚焦化工工业生产管控及优化闭环垂直领域",
          en:
            "Focus on chemical industry production control and optimization of closed-loop vertical fields",
        },
        // 领先
        {
          img: require("../../assets/home/n-h-banner3.png"),
          cn: "深耕工业生产本质，建立核心技术优势",
          en:
            "Deeply cultivate the essence of industrial production and establish a leading edge in core technology",
        },
      ],
      list1: [
        {
          icon: require("../../assets/home/n-h-page1-icon1.png"),
          title: "主要业务结构",
          desc:
            "为石油石化、煤制油/化工、精细化工、天然气储运等行业提供生产运行管理、实验室信息管理、油气储运安全管控、以及能源管控优化、工艺管控优化等综合解决方案与产品。",
        },
        {
          icon: require("../../assets/home/n-h-page1-icon2.png"),
          title: "全国业务覆盖范围",
          desc:
            "依托北京总部，以及 江苏分公司、山东分公司、江西分公司、广州办事处、西安办事处、苏州办事处等地理位置优势，将业务辐射到全国主要工业省市石油化工、煤化工、精细化工、氯碱化工等行业。",
        },
        {
          icon: require("../../assets/home/n-h-page1-icon3.png"),
          title: "完善的服务支持",
          desc:
            "中智软创以先进技术提升化工行业的信息化、智能化应用水平，符合国家“两化融合”、“智能制造”、 “工业互联网”等的政策支持方向。",
        },
      ],

      list2: [
        {
          icon: require("../../assets/home/h-page2-icon1.png"),
          img: require("../../assets/home/n-h-page2-1.png"),
          list: [
            "炼油行业MOM系统套件",
            "石油化工行业MOM系统套件",
            "煤化工行业MOM系统套件",
            "LNG行业MOM系统套件",
            "机加工行业MOM系统套件",
            "PVC行业MOM系统套件",
            "注塑行业MOM系统套件",
          ],
          title: "中智工业智能MOM套件",
          desc:
            "中智工业智能MOM套件是基于MES系统构建的以生产为核心的高效、实时、准确、稳定、透明的管理平台，满足计划、调度、操作、计量等多个业务领域的物料管理、设备管理、计量管理等各项需求，可实现对生产管理各业务环节的全面管控，加强生产领域间的业务集成和数据共享，有效提高企业的生产效率，降低生产成本。",
        },
        {
          icon: require("../../assets/home/h-page2-icon4.png"),
          img: require("../../assets/home/n-h-page2-2.png"),
          list: [
            "实时数据库软件",
            "生产绩效管理软件",
            "设备全生命周期管理软件",
            "质量管理软件",
            "计量管理软件",
            "工艺操作管理软件",
            "物料管理软件",
            "化工批次管理软件",
            "能源管理软件",
            "调度排产优化软件",
            "油库智能安全生产管控软件",
          ],
          title: "中智工业生产管控软件",
          desc:
            "产品功能丰富，涉及计划、调度、工艺、操作、计量、设备、统计等多个业务领域，可以单项功能作为产品，也可以组合功能作为产品。为生产管理各业务层提供了具有针对性的管控功能，可按需求定制产品。",
        },
        {
          icon: require("../../assets/home/h-page2-icon3.png"),
          img: require("../../assets/home/n-h-page2-3.png"),
          list: [
            "复杂精馏装置实时优化",
            "蒸汽动力系统实时优化",
            "氢气系统实时优化",
          ],
          title: "中智工业集成优化软件",
          desc:
            "中智软创在英国曼彻斯特大学过程集成研究中心集成优化技术基础上，结合公司团队对于化工行业的行业理解及管理应用软件开发经验，针对中国市场应用，进行应用开发，升级产品，并保证本地化实施和长期售后服务，致力于国内集成优化技术的产业化！",
        },
      ],

      list3: [
        {
          img: require("../../assets/home/n-h-page3-1.png"),
          title: "智慧工厂库区管理解决方案",
          desc: " ",
        },
        {
          img: require("../../assets/home/n-h-page3-2.png"),
          title: "石化行业整体解决方案",
          desc:
            "支以化工产品批次管理为主线，重点对新产品研发、原料配比、工艺方案、生产排程、生产过程批次跟踪、客户需求反馈等化工生产全流程进行监控和分析，实现企业业务流程的优化和生产过程的精细化管理，指导企业合理配置资源、科学调度排产，节能降耗、挖潜增效，提升对市场变化和突发异常的应变能力，使企业生产运行处于“安稳长满优”状态。",
        },
        {
          img: require("../../assets/home/n-h-page3-3.png"),
          title: "能源装置节能优化解决方案",
          desc:
            "采用能源产耗预测、能源管网模拟、能源多周期动态优化调度等核心技术，建立能源产耗预测模型、能源管网模拟模型和能源系统优化调度模型，在能源平衡与优化调度平台上自动给出各种能源介质的优化调度和分配方案，实现工业企业主要能源系统（燃料气、氢气、蒸汽、电力、水系统等）的优化调度和运行，提高企业能源综合利用效率和能源管理水平。",
        },
        {
          img: require("../../assets/home/n-h-page3-4.png"),
          title: "智慧实验室管理解决方案",
          desc:
            "实现化验室日常业务管理和质量数据管理的标准化、流程化、信息化，整体提升企业质量管理的效率和精细化水平，为企业的质量控制和生产优化提供及时可靠的支撑。",
        },
        {
          img: require("../../assets/home/n-h-page3-5.png"),
          title: "机加工行业智能制造解决方案",
          desc:
            "以订单及时交付为核心，实现从生产订单到产品完工的全部生产活动的优化管理，运用及时、准确的采集信息，指导、启动、响应并记录管理工厂的生产活动，从而能够对条件的变化做出迅速的响应、提高工厂运作过程的透明度和执行效率，降低生产库存、提高订单交付及时率、合格率，提高客户满意度，提升企业品牌形象和核心竞争力。",
        },
        {
          img: require("../../assets/home/n-h-page3-6.png"),
          title: "区域级工业互联网解决方案",
          desc: " ",
        },
      ],

      list4: [
        {
          list: [
            {
              title: "",
              data: [
                { img: require("../../assets/home/h-page4-48.png") },
                // { img: require("../../assets/home/h-page4-49.png") },
                { img: require("../../assets/home/h-page4-50.png") },
                { img: require("../../assets/home/h-page4-51.png") },
                { img: require("../../assets/home/h-page4-52.png") },
                { img: require("../../assets/home/h-page4-53.png") },
                { img: require("../../assets/home/h-page4-54.png") },
                // { img: require("../../assets/home/h-page4-33.png") },
                { img: require("../../assets/home/h-page4-55.png") },
                // { img: require('../../assets/home/h-page4-56.png'), },
                { img: require("../../assets/home/h-page4-57.png") },
                { img: require("../../assets/home/h-page4-58.png") },
                // { img: require("../../assets/home/h-page4-59.png") },
                { img: require("../../assets/home/h-page4-60.png") },
                { img: require("../../assets/home/h-page4-61.png") },
                { img: require("../../assets/home/h-page4-62.png") },
              ],
            },
          ],
        },
        {
          list: [
            {
              title: "流程行业",
              data: [
                { img: require("../../assets/home/h-page4-31.png") },
                { img: require("../../assets/home/h-page4-32.png") },
                // { img: require("../../assets/home/h-page4-33.png") },
                { img: require("../../assets/home/h-page4-34.png") },
                { img: require("../../assets/home/h-page4-35.png") },
                { img: require("../../assets/home/h-page4-36.png") },
                { img: require("../../assets/home/h-page4-37.png") },
                // { img: require("../../assets/home/h-page4-38.png") },
                { img: require("../../assets/home/h-page4-39.png") },
                { img: require("../../assets/home/h-page4-40.png") },
                { img: require("../../assets/home/h-page4-41.png") },
                { img: require("../../assets/home/h-page4-42.png") },
                { img: require("../../assets/home/h-page4-43.png") },
                // { img: require('../../assets/home/h-page4-44.png'), },
                { img: require("../../assets/home/h-page4-45.png") },
                { img: require("../../assets/home/h-page4-46.png") },
                { img: require("../../assets/home/h-page4-47.png") },
                { img: require("../../assets/home/h-page4-63.png") },
                { img: require("../../assets/home/h-page4-64.png") },
                { img: require("../../assets/home/h-page4-65.png") },
                { img: require("../../assets/home/h-page4-66.png") },
              ],
            },
            {
              title: "离散行业",
              data: [
                { img: require("../../assets/home/h-page4-5.png") },
                { img: require("../../assets/home/h-page4-7.png") },
                { img: require("../../assets/home/h-page4-9.png") },
                { img: require("../../assets/home/h-page4-10.png") },
                { img: require("../../assets/home/h-page4-11.png") },
                { img: require("../../assets/home/h-page4-12.png") },
                { img: require("../../assets/home/h-page4-18.png") },
                { img: require("../../assets/home/h-page4-20.png") },
                { img: require("../../assets/home/h-page4-21.png") },
                { img: require("../../assets/home/h-page4-22.png") },
                { img: require("../../assets/home/h-page4-23.png") },
                { img: require("../../assets/home/h-page4-28.png") },
              ],
            },
          ],
        },

        // { img: require('../../assets/home/h-page4-1.png'), },
        // { img: require('../../assets/home/h-page4-2.png'), },
        // { img: require('../../assets/home/h-page4-3.png'), },
        // { img: require('../../assets/home/h-page4-4.png'), },
        // { img: require('../../assets/home/h-page4-5.png'), },
        // { img: require('../../assets/home/h-page4-6.png'), },
        // { img: require('../../assets/home/h-page4-7.png'), },
        // { img: require('../../assets/home/h-page4-8.png'), },
        // { img: require('../../assets/home/h-page4-9.png'), },
        // { img: require('../../assets/home/h-page4-10.png'), },
        // { img: require('../../assets/home/h-page4-11.png'), },
        // { img: require('../../assets/home/h-page4-12.png'), },
        // { img: require('../../assets/home/h-page4-13.png'), },
        // { img: require('../../assets/home/h-page4-14.png'), },
        // { img: require('../../assets/home/h-page4-15.png'), },
        // { img: require('../../assets/home/h-page4-16.png'), },
        // { img: require('../../assets/home/h-page4-17.png'), },
        // { img: require('../../assets/home/h-page4-18.png'), },
        // { img: require('../../assets/home/h-page4-19.png'), },
        // { img: require('../../assets/home/h-page4-20.png'), },
        // { img: require('../../assets/home/h-page4-21.png'), },
        // { img: require('../../assets/home/h-page4-22.png'), },
        // { img: require('../../assets/home/h-page4-23.png'), },
        // { img: require('../../assets/home/h-page4-24.png'), },
        // { img: require('../../assets/home/h-page4-25.png'), },
        // { img: require('../../assets/home/h-page4-26.png'), },
        // { img: require('../../assets/home/h-page4-27.png'), },
        // { img: require('../../assets/home/h-page4-28.png'), },
        // { img: require('../../assets/home/h-page4-29.png'), },
        // { img: require('../../assets/home/h-page4-30.png'), },
      ],

      page2Index: 0,
      colors: ["#1A8AFF", "#086ff9", "#015BD6", "#004097", "#002E6C"],
    };
  },
  methods: {
    handleTo(item) {
      this.$router.push({ path: item });
    },
    handleChange(val) {
      this.index = val;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSetActive(i, title) {
      this.page2Index = i;
      this.$refs.carousel.setActiveItem(title);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  width: 80%;
}
@keyframes bannerAc {
  from {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}
@media screen and (min-width: 980px) {
  .banner_content {
    position: absolute;
    color: #fff;
    top: 320px;
    z-index: 999;
    left: 50%;
    margin-left: -490px;
    .banner-title {
      font-size: 2.2em;
      width: 980px;
      text-align: center;
    }
    .banner-desc {
      text-align: center;
      word-break: break-all;
      width: 980px;
      text-align: center;
      margin-top: 15px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 980px) {
  .banner_content {
    position: absolute;
    color: #fff;
    top: 150px;
    z-index: 999;
    padding: 0 25px;
    .banner-title {
      font-size: 1.2em;
      text-align: center;
    }
    .banner-desc {
      text-align: center;
      word-break: break-all;
      text-align: center;
      margin-top: 15px;
    }
  }
}

.el-collapse {
  border: none;
}
.el-collapse-item {
  border-radius: 5px;
  padding: 10px 20px;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.feature {
  display: flex;
  justify-content: space-around;
  margin: 25px;
}

.page_container {
  background-color: #fff;
  .banneracive {
    animation: bannerAc 5s;
  }
  .banner {
    height: 100%;
    width: 100%;
    background-size: cover !important;
  }

  .icon {
    width: 55px;
    height: 45px;
  }

  .p-page1 {
    .p-content {
      background: url("../../assets/home/n-h-page1-bg.png") no-repeat;
      background-size: cover;
      padding: 30px;
      color: #fff;
    }
  }
  .page1 {
    background-color: #fff;
    background: url("../../assets/home/n-h-page1-bg.png") no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    .page_title {
      position: absolute;
      top: 80px;
      left: 50%;
      margin-left: -490px;
      width: 980px;
      text-align: center;
      font-size: 2em;
    }
    .page_item_container {
      height: 750px;
      position: relative;
      background: none;
      &:hover {
        background: linear-gradient(
          180deg,
          rgba(79, 128, 248, 0.07) 0%,
          rgba(57, 96, 224, 1) 100%
        );
      }
      &:hover .page_item {
        top: 350px;
      }
      .page_item {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 35px;
        top: 450px;
        transition: 0.5s ease-in;

        .title {
          font-size: 1em;
          line-height: 3em;
          margin-bottom: 1.5em;
        }
        .desc {
          font-size: 0.625em;
          line-height: 1.6em;
        }
      }
    }
  }
  .page2 {
    padding-bottom: 65px;
    background-image: url("../../assets/home/h-bg2.png");
    background-size: cover;
    background-repeat: no-repeat;
    .description {
      display: flex;
    }
    .item {
      display: flex;
      align-items: center;
      .dot {
        background-color: #1900dfff;
        width: 4px;
        height: 4px;
        display: inline-block;
        border-radius: 2px;
        margin-right: 10px;
      }
      .item_title {
        color: #1900df;
        line-height: 30px;
        font-size: 0.625em;
      }
    }
    .desc-l {
      width: 684px;
      background-color: #fff;
      padding: 45px 75px 45px 40px;
      .title-l {
        text-align: center;
        font-size: 1.5em;
        line-height: 40px;
        margin-bottom: 30px;
      }
      .title {
        text-align: center;
        font-size: 1.2em;
        margin-top: 35px;
      }
      .line {
        position: absolute;
        width: 200px;
        height: 2px;
        background-color: #5541caff;
        left: 120px;
        top: 45px;
      }
      .desc {
        color: #999999;
        text-align: left;
        font-size: 0.825em;
        margin-bottom: 20px;
      }
      .icon_title {
        color: #333333;
        font-size: 0.825em;
      }

      .content-l {
        position: relative;
        padding: 0 0 20px 20px;
        .page2-img {
          width: 250px;
          height: 350px;
          background-color: #0c259bff;
        }
        .img {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 20px;
          top: 20px;
          width: 250px;
          height: 350px;
        }
      }
    }
    .desc-r {
      width: 324px;
      z-index: 2;
      ul {
        height: 100%;
        li {
          position: relative;
          height: 20%;
          text-align: center;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          cursor: pointer;
          color: #ffffff;
          .left {
            border-right: 1px solid rgba(212, 234, 248, 0.5);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2em;
            width: 40%;
          }
          .right {
            width: 65%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .cn {
              font-size: 0.825em;
              line-height: 45px;
            }
          }
        }
        li.active {
          margin-left: -17%;
          width: 117%;
          color: #101010ff;
          box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
          background: url("../../assets/home/h-cisinfo.png") no-repeat 50%;
          background-size: contain;
          .left {
            -webkit-transform: translateX(-13px);
            -ms-transform: translateX(-13px);
            -o-transform: translateX(-13px);
            transform: translateX(-13px);
          }
        }
        .page2-icon {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  .p-page3 {
    background-color: #1e2046;
    color: #fff;
    padding: 40px 0;
    .page-r {
      height: 350px;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      .actitle {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 68px;
        background-color: rgba(38, 75, 248, 0.85);
        padding: 18px;
        .ac_title {
          font-size: 1.2em;
          line-height: 2em;
        }
        .desc {
          font-size: 0.825em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: -moz-box;
          -moz-line-clamp: 3;
          -moz-box-orient: vertical;
          word-wrap: break-word;
          word-break: break-all;
          white-space: normal;
        }
      }
    }
  }

  .page3 {
    background-color: #1e2046;
    color: #fff;
    .page-container {
      display: flex;
      flex-direction: row;
      .page-l {
        padding: 80px 0;
        .page3-title {
          font-size: 1.68em;
          width: 180px;
          margin-bottom: 25px;
        }
        .page3-l-item {
          padding: 15px 10px;
          width: 250px;
          cursor: pointer;
          font-size: 0.825em;
        }
        .page3-l-item_active {
          background: linear-gradient(
            90deg,
            #264bf8 0%,
            rgba(42, 108, 255, 0) 100%
          );
          border-left: 2px solid #1900df;
        }
      }
      .page-r {
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: -30px;
        margin-top: 30px;
        width: 100%;
        height: 547px;
        position: relative;
        cursor: pointer;
        &:hover .title {
          display: none;
        }
        .bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.2);
        }
        .title {
          font-size: 1.5em;
          font-weight: 600;
          color: #fff;
          line-height: 36px;
          position: absolute;
          bottom: 100px;
          left: 30px;
        }
        .actitle {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 68px;
          background-color: rgba(38, 75, 248, 0.85);
          opacity: 0;
          transition: opacity 1s ease-in;
          .ac_title {
            font-size: 1.2em;
            padding: 10px 30px;
            margin-top: 10px;
          }
          .desc {
            padding: 10px 30px;
            font-size: 0.825em;
          }
        }
        &:hover .actitle {
          opacity: 1;
        }
      }
    }
  }
  .page4 {
    padding-top: 30px;
    padding-bottom: 35px;
    background: url("../../assets/home/h-bg4.png") no-repeat;
    background-size: cover;
    .page4-tab-contain {
      text-align: center;
      margin-bottom: 20px;
      .page4-tab {
        padding: 8px 45px;
        background-color: #ccc;
        cursor: pointer;
      }
      .page4-ac-tab {
        background-color: rgba(38, 75, 248, 0.85);
        color: #fff;
      }
    }
    .page1-item {
      padding: 22px;
      &:hover {
        -webkit-animation-name: jello;
        animation-name: jello;
      }
    }
  }
}
</style>
